@font-face {
  font-family: 'atolicon';
  font-weight: normal;
  src:  url(./fonts/iconFont/atolicon.eot);
  src:  url(./fonts/iconFont/atolicon.eot) format('embedded-opentype'),
    url(./fonts/iconFont/atolicon.ttf) format('truetype'),
    url(./fonts/iconFont/atolicon.woff) format('woff'),
    url(./fonts/iconFont/atolicon.svg) format('svg');
  font-style: normal;
}

@font-face {
  font-family: Story;
  src: url(./fonts/story.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Story;
  src: url(./fonts/story_0.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Story;
  src: url(./fonts/story_i.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Story;
  src: url(./fonts/story_b_i.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/RobotoRegular.ttf);
  font-weight: 300;
}


@font-face {
  font-family: Roboto;
  src: url(./fonts/RobotoMedium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/RobotoBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/RobotoLight.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/RobotoMedium.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/RobotoMedium.ttf);
  font-weight: 500;
}


@font-face {
  font-family: TrebuchetMS;
  src: url(./fonts/TrebuchetMS.ttf);
  font-weight: 300;
}


@font-face {
  font-family: Helvetica;
  src: url(./fonts/Helvetica/HelveticaRegular/HelveticaRegular.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Helvetica;
  src: url(./fonts/Helvetica/HelveticaBold/HelveticaBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Helvetica;
  src: url(./fonts/Helvetica/HelveticaItalic/HelveticaItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Helvetica;
  src: url(./fonts/Helvetica/HelveticaBoldItalic/HelveticaBoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'icomoon';
  src:  url(./fonts/icomoon/fonts/icomoon.eot);
  src:  url(./fonts/icomoon/fonts/icomoon.eot) format('embedded-opentype'),
  url(./fonts/icomoon/fonts/icomoon.ttf) format('truetype'),
  url(./fonts/icomoon/fonts/icomoon.woff) format('woff'),
  url(./fonts/icomoon/fonts/icomoon.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(./fonts/Roboto-Regular.woff);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(./fonts/Roboto-Medium.woff);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 600;
  src: url(./fonts/Roboto-Bold.woff);
}
