@import "./global-config.scss";
@import "./global-fonts.scss";
@import "./global-icons.scss";
@import "./global-forms_will_deprecated";
@import "./global-forms";
@import "global-table";
//@import "../../build/fonts/icomoon/style.css";

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-weight: 300;
  font-style: normal;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Roboto;
  // min-width: 700px;
}

#application_root,
.appContainer,
.Guarantee {
  display: flex;
  height: 100%;
  width: 100%,
}

a,
.linkStyle {
  color: $def-text;
  cursor: pointer;
}

.linkStyle:hover {
  color: $def-button-hover;
}

h1 {
  font-size: 30px;
  letter-spacing: 0.7px;
  color: #36474f;
  margin-bottom: 15px;
}

h3 {
  font-size: 23px;
  text-align: center;
  color: #384045;
  margin-bottom: 36px;
}

h4 {
  font-size: 21px;
  color: #384045;
  margin-bottom: 8px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  color: $def-text;
  text-align: left;
  margin-top: 46px;
  margin-bottom: 20px;
}

h6 {
  font-size: 14px;
  color: $sub-text;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 32px;
}

div:focus,
span:focus,
input:focus {
  outline: none;
}

.icon__disabled {
  opacity: 0.2;
  cursor: default;
}

.icon__disabled:hover {
  opacity: 0.2;
}

// нужно перенести в стили лоадера

.iconInCircle {
  padding: 4px;
  border: 1px solid #7b7b7b;
  border-radius: 50%;
}

.line__gray {
  border: solid 1px #e9e9e9;
  margin: 10px 0;
}

.sectionDesc {
  color: rgb(155, 155, 155);
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0 18px;
}

b {
  font-weight: bold;
}

#for_bad_users {
  width: 500px;
  height: 120px;
  padding: 45px 70px 55px 70px;
  position: absolute;
  margin-top: -110px;
  margin-left: -320px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 3px;
  vertical-align: middle;
  left: 50%;
  top: 50%;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  display: block;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $loader-color;
  }
  40% {
    stroke: $loader-color;
  }
  66% {
    stroke: $loader-color;
  }
  80%,
  90% {
    stroke: $loader-color;
  }
}

.Scrollbar {
  overflow: auto;

  &::-webkit-scrollbar-button {
    display: none;
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eff1f1;
    border-radius: 0 8px 8px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-width: 2px 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #b5bbc0;
  }

  &::-webkit-resizer {
    display: none;
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &--transparent {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  &--slim {
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 0 8px 8px 0;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid #fff;
      border-radius: 8px;
      background-color: #979797;
    }
  }
}

.showScroll {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.vertical-gradient {
  position: relative;

  &::after {
    content: "";
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      white 80%
    );
    width: 100%;
    height: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
  }
}

.horizontal-gradient {
  position: relative;

  &::after {
    content: "";
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 80%);
    height: 100%;
    width: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
  }
}

.green-mark {
  font-weight: bold;
  color: #10b642;
}

.WidjetProcessLoader.circularLoader--container {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
