
.dataTable--headCheckBox {
  &.checkboxDef{
    display: inline-flex;
    vertical-align: middle;
    padding-right: 12px;
    width: auto;
  }
  .checkboxDef--rect {
    margin-right: 0;
  }
}
.tr__widget__canSelected{
  .checkboxDef--rect {
    margin-right: 0;
  }
}
// кружки статуса
.green-light, .red-light {
  width: 27px;
  height: 27px;
  border-radius: 20px;
  display: inline-block;
  color: transparent;
  overflow: hidden;
  margin-top: 2px;
}

.green-light {
  background-color: $positive-color;
}

.red-light {
  background-color: $negative-color;
}

// разделитель в даблице
.divider td {
  background: $kabinet-background;
}


// ячейки делегирования в мониторинге ККт и Хабов
.dataTableLine--partnerDelegationsButs {
  white-space: nowrap;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  > span:first-of-type{
    width: 213px
  }

  .button {
    display: inline-block;
    vertical-align: middle;
    min-width: 100px;
  }
}

// кружочки серые
.dataTableLine--noticeGreyScretchable{
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  padding: 0 6px;
  background-color: $lock-color;
  color: $sub-text;
  font-size: 14px;
  &:hover{
    cursor: pointer;
  }
}

// блок для кружочков количества устройств
.partnerDevices{
  width: 241px;
}

.partnerDevices > div{
  display: inline-block;
  width: 100px;
}

.partnerDevices > div > a{
  margin-left: 7px;
}






















