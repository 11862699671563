.icon-ic_bottle:before {
  content: "\e95a";
}
.icon-ic_download-template:before {
  content: "\e959";
}
.icon-ic_date_arrow_left:before {
  content: "\e957";
  color: #4a4a4a;
}
.icon-ic_date_arrow_right:before {
  content: "\e958";
  color: #4a4a4a;
}
.icon-ic_arrow_down:before {
  content: "\e954";
}
.icon-ic_arrow_up:before {
  content: "\e956";
}
.icon-ic-copy:before {
  content: "\e953";
}
.icon-ic_price-tags:before {
  content: "\e955";
}
.icon-ic_spinner_back:before {
  content: "\e950";
}
.icon-ic_filter:before {
  content: "\e952";
}
.icon-ic_arrow_left:before {
  content: "\e94d";
}
.icon-ic_double_arrow_left:before {
  content: "\e94e";
}
.icon-ic_double_arrow_right:before {
  content: "\e900";
}
.icon-ic_add:before {
  content: "\e901";
}
.icon-ic_add_alert:before {
  content: "\e902";
}
.icon-ic_check:before {
  content: "\e903";
}
.icon-ic_close:before {
  content: "\e904";
}
.icon-ic_content_paste:before {
  content: "\e905";
}
.icon-ic_credit_card:before {
  content: "\e906";
}
.icon-ic_date_range:before {
  content: "\e907";
}
.icon-ic_delete:before {
  content: "\e908";
}
.icon-ic_edit:before {
  content: "\e909";
}
.icon-ic_email:before {
  content: "\e90a";
}
.icon-ic_email2:before {
  content: "\e90b";
}
.icon-ic_equalizer:before {
  content: "\e90c";
}
.icon-ic_filter_list:before {
  content: "\e90d";
}
.icon-ic_headset_mic:before {
  content: "\e90e";
}
.icon-ic_help:before {
  content: "\e90f";
}
.icon-ic_home2:before {
  content: "\e910";
}
.icon-ic_play_arrow:before {
  content: "\e911";
}
.icon-ic_remove_red_eye:before {
  content: "\e912";
}
.icon-ic_search:before {
  content: "\e913";
}
.icon-ic_settings:before {
  content: "\e914";
}
.icon-ic_shopping_cart:before {
  content: "\e915";
}
.icon-ic_swap:before {
  content: "\e916";
}
.icon-ic_shop:before {
  content: "\e917";
}
.icon-ic_transaction:before {
  content: "\e918";
}
.icon-ic_statistic:before {
  content: "\e919";
}
.icon-ic_servise:before {
  content: "\e91a";
}
.icon-ic_clock:before {
  content: "\e91b";
}
.icon-ic_file-text:before {
  content: "\e91c";
}
.icon-ic_radio-checked:before {
  content: "\e91d";
}
.icon-ic_radio-unchecked:before {
  content: "\e91e";
}
.icon-ic_book:before {
  content: "\e91f";
}
.icon-ic_star-empty:before {
  content: "\e920";
}
.icon-ic_star-full:before {
  content: "\e921";
}
.icon-ic_home:before {
  content: "\e922";
}
.icon-ic_chat:before {
  content: "\e923";
}
.icon-ic_books:before {
  content: "\e924";
}
.icon-ic_newspaper:before {
  content: "\e925";
}
.icon-ic_wrench:before {
  content: "\e926";
}
.icon-ic_rouble:before {
  content: "\e927";
}
.icon-ic_info:before {
  content: "\e928";
}
.icon-ic_delete2:before {
  content: "\e929";
}
.icon-ic_job:before {
  content: "\e92a";
}
.icon-ic_pause:before {
  content: "\e92b";
}
.icon-ic_plus:before {
  content: "\e92c";
}
.icon-ic_ready:before {
  content: "\e92d";
}
.icon-ic_time:before {
  content: "\e92e";
}
.icon-ic_kassa:before {
  content: "\e92f";
}
.icon-ic_megafon:before {
  content: "\e930";
}
.icon-ic_update:before {
  content: "\e931";
}
.icon-ic_news:before {
  content: "\e932";
}
.icon-icon-ic_podcast2:before {
  content: "\e933";
}
.icon-ic_books2:before {
  content: "\e934";
}
.icon-ic_type_card:before {
  content: "\e935";
}
.icon-ic_schet:before {
  content: "\e936";
}
.icon-ic_arrow_right:before {
  content: "\e937";
}
.icon-ic_key:before {
  content: "\e938";
}
.icon-ic_kassa2:before {
  content: "\e939";
}
.icon-ic_person:before {
  content: "\e93a";
}
.icon-ic_clients:before {
  content: "\e93b";
}
.icon-ic_download_to_kkt:before {
  content: "\e93c";
}
.icon-ic_stop_update:before {
  content: "\e93d";
}
.icon-ic_update2:before {
  content: "\e93e";
}
.icon-ic_upload_to_kkt:before {
  content: "\e93f";
}
.icon-ic_upload_to_server:before {
  content: "\e940";
}
.icon-ic_no_wranch:before {
  content: "\e941";
}
.icon-ic_danger:before {
  content: "\e942";
}
.icon-ic_phone:before {
  content: "\e943";
}
.icon-ic_flach_ico:before {
  content: "\e944";
}
.icon-ic_warning:before {
  content: "\e945";
}
.icon-ic_pause1:before {
  content: "\e946";
}
.icon-ic_burger:before {
  content: "\e947";
}
.icon-ic_nastroek_kkt:before {
  content: "\e948";
}
.icon-ic_location:before {
  content: "\e949";
}
.icon-ic_shablon_cheka:before {
  content: "\e94a";
}
.icon-ic_kkt_mini:before {
  content: "\e94b";
}
.icon-ic_hub:before {
  content: "\e94c";
}
.icon-ic_bell:before {
  content: "\e951";
}
.icon-ic_database:before {
  content: "\e964";
}
.icon-ic_bubbles:before {
  content: "\e970";
}
.icon-ic_spinner:before {
  content: "\e984";
}
.icon-ic_key2:before {
  content: "\e98d";
}
.icon-ic_briefcase:before {
  content: "\e9ae";
}
.icon-ic_download_proshivka2:before {
  content: "\e9c5";
}
.icon-ic_download_nastroiki2:before {
  content: "\e9c7";
}
.icon-ic_hangouts:before {
  content: "\ea8e";
}
.icon-ic_telegram:before {
  content: "\ea95";
}
.icon-ic_table:before {
  content: "\f0ce";
}
.icon-ic_double_arrow_right2:before {
  content: "\e94f";
}