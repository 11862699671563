.ContainerLoader {
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  &__parentNode {
    transition: all 0.4s ease;
    &_transparent {
      opacity: 0.2;
      filter: blur(2px);
      pointer-events: none;
    }
  }
}
