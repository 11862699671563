

/* Сюда пишем новые стили по формам переносим старые по возможности*/

h3.form--h-title{
  font-size: 24px;
  text-align: center;
  color: #384045;
  margin-bottom: $form-row-def-margin-bottom;
  padding: 0 10px;
}

h5.form--h-title{
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 8px;
  padding: 0 10px;
}

.form--row {
  margin-bottom: $form-row-def-margin-bottom;
  > * {
    width: 100%;
  }
}

.form--twoColumns {
  display: flex;
  justify-content: space-between;
  > * {
    width: calc(50% - 8px);
  }
}

.form--twoColumns__FromRight {
  flex-direction: row-reverse;
}

.form--threeCol {
  display: flex;
  justify-content: space-between;
  > * {
    width: calc(#{$x4} - 8px);
  }
}


@media (max-width: 800px) {
  .form--twoColumns {
    display: block;
    > * {
      width: 100%;
      margin-bottom: $form-row-def-margin-bottom;
    }
  }

  .form--threeCol{
    display: block;
    > * {
      width: 100%;
      margin-bottom: $form-row-def-margin-bottom;
    }
  }
}

