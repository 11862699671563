@import 'style/global-config.scss';

.VncContainer {
  flex-grow:1;
  display: flex;
  flex-direction: column;
  position:relative;
  padding: 32px;

  &__loginBlock {
    max-width: 358px;
  }
  &__logo {
    text-align: center;
  }
  &__frameLoader{
    position:absolute;
    left:50%;
    top:50%;
  }
  &__iframe {
    width: 100%;
    flex-grow: 1;
    border: none;
    margin-bottom:16px;
  }
  &__popup {
    text-align: center;
  }
  &__popupConfim {
    display: flex;
    justify-content: center;
  }
  &__popupConfimButton {
    width: auto;
  }
  &__howTo{
    display:flex;
    align-items:baseline;
    padding: 8px 0;
  }
  &__howToNumber{
    color:white;
    background:$palette-main-1;
    min-width:24px;
    height:24px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    margin-right:16px;
    font-size: 12px;
  }
  &__howToConfirmBlock{
      display:flex;
      justify-content:flex-end;
  }
  &__howToConfirm{
      width:120px;
  }
  &__connectButton{
    width:auto;
  }
  &__connectionButtonContainer{
    display:flex;
    justify-content:flex-end;
  }
  &__loginTitle{
    display:flex;
    align-items:center;
  }
  &__beta{
    background: $palette-negative-1;
    color:white;
    margin-left: 8px;
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 16px;
  }

  &__buttonLink {
    font-size: 16px;
    width: 300px;
  }
  
}
.VncDisconnectDialog{
  & .popup__close{
    display:none;
  }
  &__actions{
    display:flex;
    justify-content:flex-end;
  }
  &__actionButton{
    width:120px;
    margin-left: 16px;
  }
  &__headline{
    font-size: 24px;
  }
}