

// UI KIT color
$palette-main-1: #36474f; // основной
$palette-main-2: #5c6a70; // 80%
$palette-main-3: #f6f6f7; // 5%

$palette-primary-1: #358ed7; // основной
$palette-primary-2: #257dc4; // hover
$palette-primary-3: #18649f; // dark
$palette-primary-4: #5da5df; // 80%
$palette-primary-5: #86bbe7; // 60%
$palette-primary-6: #b9d8f1; // 35%
$palette-primary-7: #d7e8f7; // 20%

$palette-positive-1: #7ed321; // основной
$palette-positive-2: #71c101; // hover
$palette-positive-3: #66b101; // dark
$palette-positive-4: #b2e47a; // 60%
$palette-positive-5: #e5f6d3; // 20%

$palette-negative-1: #f01526; // основной
$palette-negative-2: #d50115; // hover
$palette-negative-3: #9b0001; // dark
$palette-negative-4: #f9a1a8; // 40%
$palette-negative-5: #fde7e9; // 10%
$palette-negative-6: #fab8bd; // 30%
$palette-negative-7: #fef3f4; // 50%

$palette-noactive-1: #828d91; // 60%
$palette-noactive-2: #c5c5c5;
$palette-noactive-3: #9aa3a7; // 50%
$palette-noactive-4: #afb5b9; // 40%
$palette-noactive-5: #d7dadc; // 20%
$palette-noactive-6: #eaeced; // 10%\
$palette-noactive-7: #f8f9fa; // 10%
$palette-noactive-8: #fafafa; // 10%

$palette-warning-1: #f8c51c;
$palette-warning-2: #ffc000;

$palette-white: #fff;
$palette-black: #000;
//

$def-background: #fff;
$select-background: #e9e9e9;
$kabinet-background: #f3f4f5;
$disable-button: #b7b7b8;
$disable-button-text: #e9e9e9;
$logo-background: transparent;
$add-background: #93959b;
$left-panel-background: #313334;

$popup: #fff;
$border: #d8d8d8;
$border-hover: #707676;
$border-active: #919797;
$placeholder: #c0c4c4;
$def-text: #333333;
$add-text: #ffffff;
$sub-text: #666666;
$text-color: #36474f;

$def-button: #f01526;
$def-error: #f01526;
$def-button-hover: #e54c4e;
$def-button-text: #fff;
$def-helper: #000000;
$def-helper-text: #f4f4f4;
$def-hover: #f6474d;

$def-selector: #ff5a5f;
$def-left-button: #ffffff;
$secondary-text: #8d8d8d;
$positive-color: #10b642;
$warn-color: #febe09;
$negative-color: #f01526;
$semi-negative-color: #ffb8ba;
$middle-negative-color: #fe595f;

$neutral-color: #ffb400;
$unknown-color: #f3f4f5;
$out-color: #9d9d9d;
$icon-color: #646a6e;
$addition-color: #58c2fe;
$lock-color: #f3f4f5;
$loader-color: #ff5a5f;
$active-color: #358ed7;
$active-hover-color: #2e84cb;
$active-shadow-color: rgba(53, 142, 215, 0.5);
$checkbox-active: $active-color;

$body-bg-color: #f7f7f7;
$menu-bg-color: #25353f;
$tab-inactive-background: #ebeced;
$tab-hover-background: #e1e2e3;
$tab-hover-color: #0e5086;
$tab-border-color: rgba(151, 151, 151, 0.16);

/*Цвета для зааявок в сервисе*/
$request-color-new: #6cd588;
$request-color-confirm: #febe09;
$request-color-process: #4a90e2;
$request-color-work: #bd10e0;
$request-color-done: #9b9b9b;
$request-color-rejected: #fe595f;

/* Device sizes */
$maxTabletSize: 1200px;
$maxMobileSize: 568px;

$mediaFullMenu: 1200px;
$FullMenuWidth: 246px;
$SmallMenuWidth: 80px;

$graph-color-1: #020202;
$graph-color-2: #5041c5;
$graph-color-3: #7fb3fc;
$graph-color-4: #f56a6b;
$graph-color-5: #76c3ae;
$graph-color-6: #cb61da;
$graph-color-7: #a8df3d;
$graph-color-8: #3168ca;
$graph-color-9: #44e4da;
$graph-color-10: #fed339;

$x12: 100%;
$x11: 91.66666667%;
$x10: 83.33333333%;
$x9: 75%;
$x8: 66.66666667%;
$x7: 58.33333333%;
$x6: 50%;
$x5: 41.66666667%;
$x4: 33.33333333%;
$x3: 25%;
$x2: 16.66666667%;
$x1: 8.33333333%;

$popup-padding: 84px;

//-- Shadows
$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

// Forms
$form-row-def-margin-bottom: 32px;
